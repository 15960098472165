<template>
  <Content title="产品资源">
    <div class="product-list d-flex justify-content-between flex-wrap">
      <DesktopProductItem
        v-for="(product, index) in products"
        :key="index"
        :subtitle="product.subtitle"
        :imageName="product.imageName"
        :descList="product.descList"
      />
    </div>
  </Content>
</template>

<script>
import Content from '@/shared/components/content.vue'
import DesktopProductItem from './desktop-product-item.vue'
import { products } from './data'

export default {
  name: 'DesktopProductList',
  components: { Content, DesktopProductItem },
  setup() {
    return { products }
  },
}
</script>
