<template>
  <div class="app-download th-pt-35 th-pb-50">
    <div class="text-center text-white">
      <h3 class="th-fs-22 fw-bold">特航商旅应用程序下载</h3>
      <div class="th-fs-14 th-mt-20">
        <div>为客户提供安全、便捷、高效、用户体验一流的</div>
        <div>个性化商旅解决方案</div>
      </div>
    </div>
    <div class="d-flex justify-content-center th-mt-50">
      <div class="text-center th-mr-26">
        <img src="/images/tmc-app@2x.png" class="qrcode" />
        <p class="text-white th-fs-14 th-mt-10">特航商旅APP</p>
      </div>
      <div class="text-center th-ml-26">
        <img src="/images/wechat-account@2x.png" class="qrcode" />
        <p class="text-white th-fs-14 th-mt-10">特航航空公众号</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MobileAppDownload',
}
</script>

<style lang="scss" scoped>
.app-download {
  background: url('/images/download-app@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.qrcode {
  object-fit: contain;
  width: 100px;
  height: 100px;
}
</style>
