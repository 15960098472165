<template>
  <Navbar pageName="index" />
  <template v-if="isMobile">
    <MobileBanner />
    <MobileProduct />
    <MobileLeadingSupplier />
    <MobileHonor />
    <MobileAppDownload />
    <MobileFooter />
  </template>
  <template v-else>
    <DesktopBanner />
    <DesktopProduct />
    <DesktopLeadingSupplier />
    <DesktopProgramme />
    <DesktopWhyUs />
    <DesktopUrgentPlan />
    <DesktopHonor />
    <DesktopAppDownload />
    <Feature />
    <DesktopFooter />
  </template>
</template>

<script>
import DesktopFooter from '@/shared/components/footer/desktop-footer.vue'
import Feature from '@/shared/components/feature.vue'
import MobileFooter from '@/shared/components/footer/mobile-footer.vue'
import Navbar from '@/shared/components/navbar.vue'

import DesktopAppDownload from './components/app-download/desktop-app-download.vue'
import DesktopBanner from './components/banner/desktop-banner'
import DesktopHonor from './components/honor/desktop-honor.vue'
import DesktopLeadingSupplier from './components/leading-supplier/desktop-leading-supplier.vue'
import DesktopProduct from './components/product/desktop-product.vue'
import DesktopProgramme from './components/programme/desktop-programme.vue'
import DesktopUrgentPlan from './components/urgent-plan/desktop-urgent-plan.vue'
import DesktopWhyUs from './components/why-us/desktop-why-us.vue'
import MobileAppDownload from './components/app-download/mobile-app-download.vue'
import MobileBanner from './components/banner/mobile-banner.vue'
import MobileHonor from './components/honor/mobile-honor.vue'
import MobileLeadingSupplier from './components/leading-supplier/mobile-leading-supplier.vue'
import MobileProduct from './components/product/mobile-product.vue'
import { isMobile } from '@/shared/utils/equipment.utils.js'

export default {
  components: {
    DesktopAppDownload,
    DesktopBanner,
    DesktopFooter,
    DesktopHonor,
    DesktopLeadingSupplier,
    DesktopProduct,
    DesktopProgramme,
    DesktopUrgentPlan,
    DesktopWhyUs,
    Feature,
    MobileAppDownload,
    MobileBanner,
    MobileFooter,
    MobileHonor,
    MobileLeadingSupplier,
    MobileProduct,
    Navbar,
  },
  setup() {
    return { isMobile: isMobile() }
  },
}
</script>
