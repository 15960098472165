<template>
  <Content title="荣誉资质">
    <div class="qualified-honor-container">
      <div v-for="(honor, i) in honors" :key="i" class="qualified-honor-item th-p-48">
        <div class="d-flex align-items-center honor-title">
          <div class="honor-icon-wrapper d-flex justify-content-center align-items-center">
            <img width="34" :src="honor.icon" />
          </div>
          <div class="honor-title-desc">{{ honor.name }}</div>
        </div>
        <div class="honor-content" v-html="honor.content"></div>
      </div>
    </div>
    <div class="scroll-vertical-wrapper">
      <a class="scroll-prev" href="javascript:void(0)" role="button" data-slide="prev">
        <span class="carousel-preview-btn dark" aria-hidden="true"></span>
      </a>
      <a class="scroll-next" href="javascript:void(0)" role="button" data-slide="next">
        <span class="carousel-next-btn dark" aria-hidden="true"></span>
      </a>
    </div>
  </Content>
</template>
<script>
import Content from '@/shared/components/content.vue'
import $ from 'jquery'
import { honors } from './data'

export default {
  name: 'DesktopHonor',
  components: { Content },
  setup() {
    return { honors }
  },
  mounted() {
    const $honorContainer = $('.qualified-honor-container')
    let scrollPosition = 0
    $('.scroll-next').click(() => {
      if (scrollPosition < 1117) {
        scrollPosition += 408
      }

      $('.qualified-honor-container').scrollLeft(scrollPosition)
    })

    $('.scroll-prev').click(() => {
      scrollPosition -= 408
      scrollPosition = scrollPosition < 0 ? 0 : scrollPosition
      $honorContainer.scrollLeft(scrollPosition)
    })
  },
}
</script>

<style scoped>
.qualified-honor-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.qualified-honor-container::-webkit-scrollbar {
  display: none;
}

.qualified-honor-item {
  width: 408px;
  padding: 48px;
  background-color: #fff;
  box-shadow: -1px 13px 20px 0px rgba(240, 242, 244, 1);
  border-radius: 5px;
  transition: all 0.3s ease-out;
  margin-right: 40px;
  flex: none;
}

.honor-title {
  margin-bottom: 26px;
}

.honor-title-desc {
  color: #4b7ef6;
  font-size: 21px;
}

.honor-content {
  color: #324148;
  font-size: 18px;
}

.honor-icon-wrapper {
  margin-right: 24px;
  background-color: #ffffff;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.22);
}

.qualified-honor-item:hover {
  background-color: rgba(75, 126, 246, 1);
}

.qualified-honor-item:hover .honor-title-desc {
  color: #ffffff;
}

.qualified-honor-item:hover .honor-content {
  color: #ffffff;
}

.scroll-vertical-wrapper {
  display: flex;
  margin-top: 56px;
  padding-left: 158px;
}

.scroll-prev,
.scroll-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: -1px 13px 20px 0px rgba(240, 242, 244, 1);
  transition: all 0.3s ease-out;
  margin-right: 16px;
}

.scroll-prev:hover,
.scroll-prev:focus,
.scroll-next:hover,
.scroll-next:focus {
  background-color: #4b7ef6;
  box-shadow: 0px 25px 25px 0px rgba(205, 219, 255, 1);
}

.carousel-next-btn,
.carousel-preview-btn {
  background-image: url('/images/mobile/carousel-next-dark.png');
}

.carousel-next-btn.dark,
.carousel-preview-btn.dark {
  background-image: url('/images/mobile/carousel-prev-dark.png');
}

.carousel-next-btn.dark {
  transform: rotate(180deg);
}

.carousel-preview-btn.dark {
  transform: rotate(0);
}

.scroll-prev:hover .carousel-preview-btn.dark,
.scroll-prev:focus .carousel-preview-btn.dark {
  transform: rotate(180deg);
}

.scroll-next:hover .carousel-next-btn.dark,
.scroll-next:focus .carousel-next-btn.dark {
  transform: rotate(0);
}

.scroll-prev:hover .carousel-preview-btn,
.scroll-prev:focus .carousel-preview-btn,
.scroll-next:hover .carousel-next-btn,
.scroll-next:focus .carousel-next-btn {
  background-image: url('/images/mobile/carousel-next-light.png');
}
</style>
