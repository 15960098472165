export const honors = [
  {
    name: '公务票预订资质',
    content:
      '政府采购机票项目合同书；2019年公务销售全国第四，广东第一；2018年公务销售全国第五，广东第一（信息来源于政府采购机票管理网站）',
    icon: '/images/index-gp-icon.png',
  },
  {
    name: '航协资质',
    content: '航空运输销售代理业务一、二类资格认证书；IATA国际客运销售代理人认可证书',
    icon: '/images/index-airline-certificate.png',
  },
  {
    name: '良好航司关系',
    content: '荣获多次国内外航司奖项“优秀代理人”，“最佳合作伙伴”',
    icon: '/images/index-airline-cooperation.png',
  },
  {
    name: '行业资质',
    content: '荣获“全国文明诚信单位”；深圳市航空运输协会副会长单位',
    icon: '/images/index-biz-cert.png',
  },
  {
    name: '社会责任',
    content:
      '<div>2014后荣获深圳青少年最依赖品牌</div><div>2016年荣获年度最佳雇主提名奖</div><div>2019年荣获“金牌服务行政商”</div>',
    icon: '/images/index-social-responsibility.png',
  },
]
