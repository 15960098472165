<template>
  <Content title="荣誉资质">
    <div id="honorCarousel" class="carousel slide" data-ride="carousel" data-interval="false">
      <div class="carousel-inner h-100">
        <div
          v-for="(honer, i) in honors"
          :key="i"
          :class="{ active: i === 0 }"
          class="carousel-item comment-item"
        >
          <div class="qualified-honor-item th-p-24">
            <div class="d-flex align-items-center">
              <div class="honor-icon-wrapper th-mr-12">
                <img :src="honer.icon" />
              </div>
              <div class="honor-title-desc th-fs-13 fw-bold">{{ honer.name }}</div>
            </div>
            <div class="honor-content th-fs-12 th-mt-10" v-html="honer.content"></div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#honorCarousel" role="button" data-slide="prev">
        <span class="carousel-preview-btn" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#honorCarousel" role="button" data-slide="next">
        <span class="carousel-next-btn" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </Content>
</template>
<script>
import Content from '@/shared/components/content.vue'
import { honors } from './data'

export default {
  name: 'MobileHonor',
  components: { Content },
  setup() {
    return { honors }
  },
}
</script>

<style scoped>
.qualified-honor-item {
  width: 240px;
  min-height: 165px;
  background-color: white;
  box-shadow: -1px 13px 20px 0px rgb(240 242 244);
  border-radius: 5px;
  transition: all 0.3s ease-out;
  margin-right: 40px;
  flex: none;
  margin: 0 auto;
}

.honor-title {
  margin-bottom: 26px;
}

.honor-title-desc {
  color: #4b7ef6;
}

.honor-content {
  color: #324148;
}

.honor-icon-wrapper {
  background-color: #ffffff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.22);
  position: relative;
}

.honor-icon-wrapper img {
  position: absolute;
  width: 17px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.carousel-control-prev,
.carousel-control-next {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: -1px 13px 20px 0px rgba(240, 242, 244, 1);
  transition: all 0.3s ease-out;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  background-color: #4b7ef6;
  box-shadow: 0px 25px 25px 0px rgba(205, 219, 255, 1);
}

.carousel-control-prev:hover .carousel-preview-btn,
.carousel-control-prev:focus .carousel-preview-btn,
.carousel-control-next:hover .carousel-next-btn,
.carousel-control-next:focus .carousel-next-btn {
  background-image: url('/images/mobile/carousel-next-light.png');
}
</style>
