<template>
  <div class="product-list-item d-flex th-p-22 th-my-12 mx-0">
    <div class="product-img-container th-py-22 th-px-17">
      <img :src="`/images/pages/index/product-` + imageName + `.png`" />
    </div>
    <div class="product-text-container th-ml-20">
      <p class="product-resource-subtitle th-fs-26 fw-bold lh-1 th-mt-10">{{ subtitle }}</p>
      <div class="product-resource-desc th-mt-12" v-for="(desc, i) in descList" :key="i">
        <div class="th-fs-16 th-mt-4 lh-1">{{ desc }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexProductItem',
  props: { subtitle: String, imageName: String, descList: Array },
  setup() {},
}
</script>

<style lang="scss" scoped>
.product-list-item {
  width: 365px;
  border-radius: 18px;
  box-shadow: 0px 4px 20px 0px #f0f2f4;
}

.product-img-container {
  width: 90px;
  height: 100px;
  background: rgba(107, 162, 254, 0.1);
  border-radius: 12px;
}

.product-img-container img {
  width: 100%;
}

.product-text-container {
  flex: 1;
}

.product-resource-subtitle {
  color: #4b7ef6;
}

.product-resource-desc {
  color: #333333;
}

.product-list-item:hover {
  cursor: pointer;
  background: #4b7ef6;
}

.product-list-item:hover .product-img-container {
  background: #fff;
}

.product-list-item:hover .product-resource-subtitle,
.product-list-item:hover .product-resource-desc {
  color: #fff;
}
</style>
