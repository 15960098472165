<template>
  <Content title="为什么选择我们" class="urgent-plan">
    <div class="d-flex wrapper">
      <div>
        <img height="550" src="/images/airplane-passenger@2x.png" alt="" />
      </div>
      <div class="trapezoid">
        <div id="urgentPlan" class="carousel slide" data-ride="carousel" data-interval="false">
          <ol class="carousel-indicators">
            <li data-target="#urgentPlan" data-slide-to="0" class="active"></li>
            <li data-target="#urgentPlan" data-slide-to="1"></li>
            <li data-target="#urgentPlan" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div>
                <div class="mark"></div>
                <h5 class="text-white customer-name">
                  原预订航班无法正常起飞，但客户必须在指定时间到达北京的紧急处理：
                </h5>
                <div class="text-white">
                  6月某公务单位一领导，必须在指定日期指定时间到达
                  北京开会，由于天气原因，航司大面积航班取消或无法
                  正常按时起飞，我司工作人员根据工作经验，在查询香
                  港天气和航班动态情况下，推荐客户从香港飞往北京， 最终客户准时到达了会议现场。
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div>
                <div class="mark"></div>
                <h5 class="text-white customer-name">国际行程紧急出行应急方案：</h5>
                <div class="text-white">
                  每年一月美国拉斯维加斯全球展会，火爆程度让机票、酒店异常难预订，为了让客户顺利出行，我司提前半年，由专人跟进客户展会酒店和机票情况，由于客户特殊性，在离展会提15天才能确认名单，增加了我司预存风险，我司领导在第一时间联系多方航司申请机位，我司客服24小时刷新系统，最终在三天内把客户出行人机票和酒店预订成功，让中国企业在世界崭露一头。
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div>
                <div class="mark"></div>
                <h5 class="text-white customer-name">客户需要但未能及时确认行程的处理方案：</h5>
                <div class="text-white">
                  7月某集团高管需从波兰赶回国内召开紧急会议，但由于特殊原因一直不能确认具体日期、时间，我司为了保障高管行程，顶着被航司处罚的风险，计划了多种行程方案，方便客户随时出行，并安排专人从上班时间一直跟进到下班凌晨3点多，直到客户确认行程，并顺利回到了香港。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>
<script>
import Content from '@/shared/components/content.vue'

export default {
  name: 'DesktopUrgentPlan',
  components: { Content },
}
</script>

<style scoped>
.trapezoid {
  border-bottom: 526px solid #5285fb;
  border-left: 150px solid transparent;
  border-right: 0 solid transparent;
  height: 0;
  width: 844px;
  position: absolute;
  right: 0;
  top: 88px;
}

.wrapper {
  position: relative;
}

#urgentPlan {
  height: 526px;
  padding-top: 104px;
  padding-right: 114px;
  padding-left: 114px;
}

.mark {
  margin-bottom: 28px;
  width: 64px;
}

.customer-name {
  font-size: 26px;
  margin-bottom: 50px;
}
</style>
