import { readonly } from 'vue'

export const products = readonly([
  {
    subtitle: '机票',
    imageName: 'airplane',
    descList: ['全球航线，一触通程', '公务机票享受折上88-95折扣'],
  },
  { subtitle: '酒店', imageName: 'hotel', descList: ['全球酒店预订', '总能给您家的温暖'] },
  {
    subtitle: '用车',
    imageName: 'car',
    descList: ['专车、包车、短租', '不同用车需求，我们同样专业'],
  },
  { subtitle: '火车票', imageName: 'train', descList: ['买票不用愁，出行永无忧'] },
  {
    subtitle: '保险',
    imageName: 'insurance',
    descList: ['每次的出行，安心，放心', '健康险、意外险、综合险等'],
  },
  { subtitle: '旅游', imageName: 'tour', descList: ['自驾、跟团、制定随心选择'] },
  { subtitle: '签证', imageName: 'passport', descList: ['全球签证服务'] },
  {
    subtitle: '其他',
    imageName: 'others',
    descList: ['船票、签证、WIFI、增值服务', '满足您的个性需求'],
  },
  { subtitle: '企采服务', imageName: 'tmc', descList: ['酒业、保险、金融、严选商城'] },
])
