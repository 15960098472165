<template>
  <div class="app-download d-flex justify-content-around flex-wrap">
    <div class="text-white">
      <h3 class="th-fs-40 fw-bold">特航商旅应用程序下载</h3>
      <div class="th-fs-22 th-mt-20">
        <div>为客户提供安全、便捷、高效、用户体验一流的</div>
        <div>个性化商旅解决方案</div>
      </div>
    </div>
    <div class="d-flex">
      <div class="text-center th-mr-52">
        <img src="/images/tmc-app@2x.png" class="qrcode" />
        <p class="text-white th-fs-22 th-mt-24">特航商旅APP</p>
      </div>
      <div class="text-center">
        <img src="/images/wechat-account@2x.png" class="qrcode" />
        <p class="text-white th-fs-22 th-mt-24">特航航空公众号</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DesktopAppDownload',
}
</script>

<style lang="scss" scoped>
.app-download {
  background: url('/images/download-app@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.qrcode {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
</style>
