<template>
  <div class="py-md container programme-container">
    <div class="d-flex justify-content-around">
      <img class="lazyload programme-img th-mt-42" data-src="/images/pages/index/programme-1.png" />
      <div>
        <div class="text-black th-mb-42 fs-1 fw-bold">
          <div class="th-fs-40">中小企业/初创团队</div>
          <div class="th-fs-40">差旅方案</div>
        </div>
        <div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">注册0门槛，0搭售</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">一键比价：多平台最低价格，省钱又高效</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">机票折上折：享受专属协议价</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">7x24小时客服：售前售后，无后顾之忧</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">报销方便：在线自助对账，一键开票</p>
          </div>
        </div>
        <button type="button" class="btn btn-primary mt-5">
          <a class="lh-1" target="_blank" href="https://web.teyixing.com/passport/register">
            <div class="text-white">立即注册</div>
          </a>
        </button>
      </div>
    </div>
  </div>
  <!-- 差旅方案 end -->

  <!-- 定制化方案 start -->
  <div class="py-md container programme-container">
    <div class="d-flex justify-content-around">
      <div>
        <div class="text-black th-mb-42 fs-1 fw-bold">
          <div class="th-fs-40">大型集团企业/国央企</div>
          <div class="th-fs-40">定制化方案</div>
        </div>
        <div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">差旅顾问进驻公司，差旅方案全程辅助</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">对接企业OA/财务等系统，一体化管控</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">人工客服随时待命，解决客户的后顾之忧</p>
          </div>
          <div class="d-flex programme-desc th-mt-27">
            <img class="th-mr-16" src="/images/pages/index/check.png" />
            <p class="text-gray m-0 th-fs-22 lh-1">差旅数据整合与分析，节省30%差旅成本</p>
          </div>
        </div>
        <button type="button" class="btn btn-primary mt-5">
          <a class="lh-1" target="_blank" href="https://web.teyixing.com/passport/register">
            <div class="text-white">立即注册</div>
          </a>
        </button>
      </div>
      <img class="lazyload programme-img th-mt-42" data-src="/images/pages/index/programme-2.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DesktopProgramme',
}
</script>
<style scoped>
.programme-img {
  width: 483px;
  height: 356px;
  object-fit: cover;
}

.programme-desc img {
  width: 27px;
  object-fit: contain;
}
</style>
