<template>
  <div class="banner">
    <div
      id="mobileBanner"
      class="carousel slide banner-carousel-m"
      data-ride="carousel"
      data-interval="false"
    >
      <ol class="carousel-indicators">
        <li data-slide-to="0" data-target="#mobileBanner" class="active"></li>
        <li data-slide-to="1" data-target="#mobileBanner"></li>
        <li data-slide-to="2" data-target="#mobileBanner"></li>
      </ol>
      <div class="carousel-inner h-100">
        <div class="carousel-item active">
          <img class="lazyload d-block w-100" data-src="/images/pages/index/mobile-banner-1.png" />
        </div>
        <div class="carousel-item position-relative">
          <img class="lazyload d-block w-100" data-src="/images/pages/index/mobile-banner-2.png" />
          <a
            class="d-block position-absolute"
            href="./tmc.html"
            style="left: 48%; right: 16%; top: 70%; bottom: 6%"
          ></a>
        </div>
        <div class="carousel-item position-relative">
          <img class="lazyload d-block w-100" data-src="/images/pages/index/mobile-banner-3.png" />
          <a
            class="d-block position-absolute"
            href="https://web.teyixing.com/passport/register"
            target="_blank"
            style="left: 50%; right: 12%; top: 64%; bottom: 13%"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lazyload from 'lazyload'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'MobileBanner',
  setup() {
    onMounted(() => {
      const images = document.querySelectorAll('img.lazyload')
      new lazyload(images)
    })
  },
}
</script>

<style lang="scss">
.carousel-indicators {
  bottom: -10px;
}

.carousel-indicators li.active {
  background-color: #fff;
}

.carousel-item {
  position: relative;
}
</style>
