<template>
  <Content title="产品资源" class="bg-white">
    <div id="productsIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
      <ol class="carousel-indicators">
        <li data-target="#productsIndicators" data-slide-to="0" class="indicator-item active"></li>
        <li data-target="#productsIndicators" data-slide-to="1" class="indicator-item"></li>
        <li data-target="#productsIndicators" data-slide-to="2" class="indicator-item"></li>
      </ol>
      <div class="carousel-inner th-pb-30">
        <div
          v-for="(colProduct, colIndex) in products"
          :key="colIndex"
          class="carousel-item"
          :class="{ active: colIndex === 0 }"
        >
          <MobileProductItem
            v-for="(product, rowIndex) in colProduct"
            :key="rowIndex"
            :subtitle="product.subtitle"
            :imageName="product.imageName"
            :descList="product.descList"
          />
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import Content from '@/shared/components/content.vue'
import MobileProductItem from './mobile-product-item.vue'
import { products } from './data'

export default {
  name: 'MobileProduct',
  components: { Content, MobileProductItem },
  setup() {
    return {
      products: [
        [products[0], products[1], products[2]],
        [products[3], products[4], products[5]],
        [products[6], products[7], products[8]],
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
// 覆盖bootstrap的预设样式
.indicator-item {
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 50px;
  border: 0;
  opacity: 1;
}

.indicator-item.active {
  background: #4b7ef6;
}
</style>
