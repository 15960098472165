<template>
  <div class="product-list-item d-flex th-p-11 th-my-10 mx-auto bg-white">
    <div class="product-img-container th-py-11 th-px-9">
      <img :src="`/images/pages/index/product-` + imageName + `.png`" />
    </div>
    <div class="product-text-container th-ml-20 th-mt-2">
      <p class="product-resource-subtitle th-fs-16 fw-bold lh-1 m-0">{{ subtitle }}</p>
      <div class="product-resource-desc th-mt-8">
        <div class="th-fs-12 th-mt-4 lh-1" v-for="(desc, i) in descList" :key="i">{{ desc }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MobileProductItem',
  props: { subtitle: String, imageName: String, descList: Array },
  setup() {},
}
</script>

<style lang="scss" scoped>
.product-list-item {
  width: 250px;
  box-shadow: 0px 2px 10px 0px rgba(240, 242, 244, 1);
  border-radius: 9px;
}

.product-img-container {
  width: 45px;
  background: rgba(107, 162, 254, 0.1);
  border-radius: 6px;
}

.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-text-container {
  flex: 1;
}

.product-resource-subtitle {
  color: #4b7ef6;
}

.product-resource-desc {
  color: #333333;
}
</style>
