<template>
  <Content title="为什么选择我们">
    <!-- 为什么选择我们 导航 start -->
    <div id="navtab" class="why-us th-mt-90">
      <ul class="nav container" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="manager-tab"
            data-toggle="tab"
            href="#manager"
            role="tab"
            aria-controls="service"
            aria-selected="true"
            >管理者</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="financial-officer-tab"
            data-toggle="tab"
            href="#financial-officer"
            role="tab"
            aria-controls="financial-officer"
            aria-selected="false"
            >财务官</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="administrative-personnel-tab"
            data-toggle="tab"
            href="#administrative-personnel"
            role="tab"
            aria-controls="administrative-personnel"
            aria-selected="false"
            >行政/人事</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="traveler-tab"
            data-toggle="tab"
            href="#traveler"
            role="tab"
            aria-controls="traveler"
            aria-selected="false"
            >出行人</a
          >
        </li>
      </ul>
    </div>
    <!-- 为什么选择我们 导航 end -->

    <!-- 为什么选择我们 内容 start -->
    <div class="tab-content">
      <div class="tab-pane active" id="manager" role="tabpanel" aria-labelledby="manager-tab">
        <div class="d-flex justify-content-between container">
          <div class="why-us-text">
            <div class="text-black th-mt-72 th-fs-22 fw-bold">高效审批</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                通过特航商旅APP、Web等多渠道操作审批操作，随时随地处理出差事宜
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                专属客服团队，20秒接听速度，提供更加定制化的7*24小时差旅服务
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                多种差旅报告数据分析组合，便于管理者了解及调整企业差旅政策
              </li>
            </ul>
            <h5 class="text-black th-mt-72 th-fs-22 fw-bold">成本管控</h5>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                从资源整合到差旅数据分析，多达5个维度的，节省高达30%的出行成本
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                与亚朵、华住等多个核心全球酒店集团直连，低至85折含早
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                南航、东航、深航航空等国内外航司享企业协议价
              </li>
            </ul>
          </div>
          <div class="why-us-img">
            <img class="lazyload" data-src="/images/pages/index/why-us-1.png" />
          </div>
        </div>
      </div>
      <div
        class="tab-pane"
        id="financial-officer"
        role="tabpanel"
        aria-labelledby="financial-officer-tab"
      >
        <div class="d-flex justify-content-between container">
          <div>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">高效省力，自动化结算</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                支持多种结算方式，对公结算支持统一开票，财务无需一一核对报销凭证
              </li>
              <li class="text-gray th-fs-18 th-mt-14">提供时时帐单、不同纬度在线差旅报告</li>
            </ul>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">合规透明</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                透明的产品价格及收费标准，满足财务成本控制
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                与亚朵、华住等多个核心全球酒店集团直连，低至85折含早
              </li>
            </ul>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">成本节省，最高节省30%差旅费用</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                月结垫资服务，避免反复请款，不占用企业资金流
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                一键导出结算报表，及时了解未结清款项及开票进度
              </li>
            </ul>
          </div>
          <div class="why-us-img">
            <img class="lazyload" data-src="/images/pages/index/why-us-2.png" />
          </div>
        </div>
      </div>
      <div
        class="tab-pane justify-content-between container"
        id="administrative-personnel"
        role="tabpanel"
        aria-labelledby="administrative-personnel-tab"
      >
        <div class="d-flex justify-content-between container">
          <div>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">支持员工自主预订，提高工作效率</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">批量导入员工信息，进行差标管控设置</li>
              <li class="text-gray th-fs-18 th-mt-14">
                多产品同时预订，提供大发票免去收集、整理发票
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                出差免垫资、免报销、免收行程单，效率更高，满意度全面升级
              </li>
            </ul>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">差旅体验升级，满意度全面升级</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">一站式售后，让员工差旅出行无后顾之忧</li>
              <li class="text-gray th-fs-18 th-mt-14">出差免垫资，效率提升，员工满意度更高</li>
            </ul>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">专业大数据分析，实时掌握差旅花费</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                月结垫资服务，避免反复请款，不占用企业资金流
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                一键导出结算报表，及时了解未结清款项及开票进度
              </li>
            </ul>
          </div>
          <div class="why-us-img">
            <img class="lazyload" data-src="/images/pages/index/why-us-3.png" />
          </div>
        </div>
      </div>
      <div class="tab-pane" id="traveler" role="tabpanel" aria-labelledby="traveler-tab">
        <div class="d-flex justify-content-between container">
          <div>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">多种预订渠道，随时随地知晓行程</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                机票、酒店、火车票、用车等一站式预订更省心
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                多产品同时预订，提供大发票免去收集、整理发票
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                App、PC、电话、微信小程序等多渠道预订，掌握行程动态
              </li>
            </ul>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">差旅体验升级，满意度全面升级</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">预订审批无缝衔接，即刻出票</li>
              <li class="text-gray th-fs-18 th-mt-14">智能授权，无需重复提交</li>
            </ul>
            <div class="text-black th-mt-72 th-fs-22 fw-bold">专业大数据分析，实时掌握差旅花费</div>
            <ul>
              <li class="text-gray th-fs-18 th-mt-14">
                7*24小时客户服务，20秒接听，支持电话、在线客服、微信客服等多种沟通渠道
              </li>
              <li class="text-gray th-fs-18 th-mt-14">
                费用公司统一支付，告别垫资事后报销复杂流程
              </li>
            </ul>
          </div>
          <div class="why-us-img">
            <img class="lazyload" data-src="/images/pages/index/why-us-4.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 为什么选择我们 内容 end -->
    <!-- 为什么选择我们 联系方式 start -->
    <div class="container">
      <div class="d-flex justify-content-around why-us-footer">
        <div>
          <div class="th-fs-22">365x24小时专业客服</div>
          <div class="th-fs-22 fw-bold">4000-900-737</div>
        </div>
        <button type="button" class="btn btn-primary p-0">
          <a href="./tmc.html" class="d-flex justify-content-center align-items-center">
            <div class="text-white me-3">产品和功能/了解</div>
            <img src="/images/pages/index/btn-more.png" style="width: 18px" />
          </a>
        </button>
      </div>
    </div>
    <!-- 为什么选择我们 联系方式 end -->
  </Content>
</template>
<script>
import Content from '@/shared/components/content.vue'

export default {
  name: 'DesktopWhyUs',
  components: { Content },
  props: { fromPage: String },
}
</script>

<style scoped>
.why-us .nav-item {
  position: relative;
  width: 160px;
}

.why-us .nav-item .nav-link::before {
  display: block;
  content: ' ';
  width: 100%;
  height: 2px;
  background-color: #999999;
  position: absolute;
  left: 0;
  top: 0;
}

.why-us .nav-item .nav-link::after {
  display: block;
  content: ' ';
  width: 24px;
  height: 24px;
  background-color: #999999;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.why-us .nav-item .nav-link {
  text-align: center;
  margin-top: 10px;
  font-size: 22px;
  color: #666666;
}

.why-us .nav-item .nav-link.active::before,
.why-us .nav-item .nav-link.active::after {
  background-color: #4b7ef6;
}

.why-us .nav-item .nav-link.active {
  color: #4b7ef6;
}

.why-us-text {
  width: 640px;
}

.why-us-img {
  position: relative;
}

.why-us-img img {
  width: 479px;
  position: absolute;
  right: 0;
  top: -72px;
}

.why-us-footer {
  width: 640px;
  color: #4b7ef6;
  margin-top: 70px;
  padding-left: 40px;
  box-sizing: content-box;
}
</style>
