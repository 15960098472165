<template>
  <div class="banner">
    <div
      id="pcBanner"
      class="carousel slide carousel-vertical banner-carousel"
      data-ride="carousel"
      data-interval="false"
    >
      <ol class="carousel-indicators">
        <li data-slide-to="0" data-target="#pcBanner" class="active"></li>
        <li data-slide-to="1" data-target="#pcBanner"></li>
        <li data-slide-to="2" data-target="#pcBanner"></li>
      </ol>
      <div class="carousel-inner h-100">
        <div class="carousel-item active position-relative">
          <img class="lazyload d-block w-100" data-src="/images/pages/index/desktop-banner-1.png" />
          <a
            class="d-block position-absolute"
            target="_blank"
            href="https://web.teyixing.com/passport/register"
            style="left: 53%; right: 20%; top: 68%; bottom: 12%"
          ></a>
        </div>
        <div class="carousel-item">
          <img class="lazyload d-block w-100" data-src="/images/pages/index/desktop-banner-2.png" />
        </div>
        <div class="carousel-item position-relative">
          <img class="lazyload d-block w-100" data-src="/images/pages/index/desktop-banner-3.png" />
          <a
            class="d-block position-absolute"
            href="./gp.html"
            style="left: 56%; right: 18%; top: 72%; bottom: 12%"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lazyload from 'lazyload'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'DesktopBanner',
  props: {
    bannerList: Array,
  },
  setup() {
    onMounted(() => {
      const images = document.querySelectorAll('img.lazyload')
      new lazyload(images)
    })
  },
}
</script>

<style lang="scss" scoped></style>
