<template>
  <Content title="中国领先的的政采供应商">
    <div id="advantageIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
      <ol class="carousel-indicators">
        <li data-target="#advantageIndicators" data-slide-to="0" class="indicator-item active"></li>
        <li data-target="#advantageIndicators" data-slide-to="1" class="indicator-item"></li>
        <li data-target="#advantageIndicators" data-slide-to="2" class="indicator-item"></li>
      </ol>
      <div class="carousel-inner th-pb-30">
        <div
          v-for="(advantage, i) in advantages"
          :key="i"
          class="carousel-item text-center"
          :class="{ active: i === 0 }"
        >
          <div
            class="advantage-item bg-white th-px-20 th-pt-27 d-flex flex-column align-items-center"
          >
            <img class="advantage-icon d-block" :src="advantage.image" />
            <div class="th-fs-16 lh-1 fw-bold th-mt-12">{{ advantage.title }}</div>
            <div class="th-fs-12 th-mt-30 advantage-desc">{{ advantage.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>
<script>
import Content from '@/shared/components/content.vue'
import { getYearsFromJanuaryFirst } from '@/shared/utils/date.utils.js'
import { advantages } from './data'
export default {
  name: 'MobileLeadingSupplier',
  components: { Content },
  setup() {
    const company_age = getYearsFromJanuaryFirst(new Date('1999-01-01'))
    const target = advantages.find(item => item.title.includes('company_age'))
    
    target.title = target.title.replace('company_age', company_age)
    target.desc =  target.desc.replace('company_age', company_age)
    
    return { advantages }
  },
}
</script>

<style lang="scss" scoped>
.content {
  background: url('/images/pages/index/leading-supplier-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.advantage-item {
  width: 180px;
  height: 210px;
  box-shadow: 0px 2px 10px 0px rgba(240, 242, 244, 1);
  border-radius: 4px;
  margin: auto;
}

.advantage-icon {
  height: 38px;
}

.advantage-desc {
  color: #333333;
}

// 覆盖bootstrap的预设样式
.indicator-item {
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50px;
  border: 0;
  opacity: 1;
}

.indicator-item.active {
  background: #4b7ef6;
}
</style>
