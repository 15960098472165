export const advantages = [
  {
    title: '180万家',
    desc: '全球合作航司酒店高达180万家，覆盖全',
    image: '/images/pages/index/advantage-1.png',
  },
  {
    title: '2000+客户合作',
    desc: '行政事业单位、公检法、医疗系、教育系等',
    image: '/images/pages/index/advantage-2.png',
  },
  {
    title: 'company_age年行业专注',
    desc: 'company_age年服务上万家大型企业，引领行业发展',
    image: '/images/pages/index/advantage-3.png',
  },
]
